/*---------------------------------------------------------------------------*\
  #COMPONENTS > #MODALS
\*---------------------------------------------------------------------------*/

@import "bootstrap/modals";


.modal-header {
	padding: $space-squish-base;
}

	.modal-header .close {
		margin-top: 1px;
	}

.modal-body {
	padding: $space-inset-base;
}

.modal-footer {
	padding: $space-inset-base;
}
