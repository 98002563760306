/*---------------------------------------------------------------------------*\
#COMPONENTS > #ACCORDIONS
\*---------------------------------------------------------------------------*/

.accordion {
	margin-bottom: 16px;
}

.accordion-heading {
	border-bottom: 1px solid #0082CA;
	padding: $space-s;
	padding-left: 0;
}

.accordion-heading.has-icon {
	border-left: 40px solid #0082CA;
	border-bottom-left-radius: 4px;
}

.accordion-title {
	margin-bottom: 0;
	margin-left: -40px;
	padding-left: 40px;
	font-size: 16px;
	font-weight: bold;
}

.accordion-title .accordion-icon {
	display: inline-block;
	position: absolute;
	margin-left: -23px;
	color: #FFFFFF;
}

.accordion-title a {
	display: inline-block;
	position: relative;
	padding-left: $space-m;
	width: 100%;
	vertical-align: middle;
	color: #0082CA;
}

.accordion-title a:focus {
	text-decoration: none;
}

.accordion-title a:hover {
	text-decoration: none;
}

.accordion-title a::after {
	position: absolute;
	top: 10%;
	right: 0;
	font-family: FontAwesome;
	font-size: 15px;
	content: "\f106";
	transition: all 0.3s;
}

.accordion-body {
	padding: $space-m;
}

.accordion-title a.collapsed::after {
	content: "\f107";
}

.accordion-heading + .accordion-collapse,
.accordion .accordion-heading + .acordion-collapse > .accordion-body {
	border-top: 0;
}
