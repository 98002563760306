/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #THEME
\*---------------------------------------------------------------------------*/

$color-theme-events-100: #DFB2D8;
$color-theme-events-300: #BA58AB;
$color-theme-events-500: #973E89;
$color-theme-events-700: #6C2C62;
$color-theme-events-900: #401A3A;

$color-theme-secretariat-100: #AEE9FF;
$color-theme-secretariat-300: #2EC6FF;
$color-theme-secretariat-500: #00AFF0;
$color-theme-secretariat-700: #0082B3;
$color-theme-secretariat-900: #005676;

$color-theme-training-100: #C3E4C1;
$color-theme-training-300: #6DBE68;
$color-theme-training-500: #4BA346;
$color-theme-training-700: #377834;
$color-theme-training-900: #244D21;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #NEUTRALS
\*---------------------------------------------------------------------------*/

$color-white: #FFFFFF;
$color-black: #000000;

$color-gray-05: #F2F2F2; // Brightness: 95%
$color-gray-10: #E6E6E6; // Brightness: 90%
$color-gray-20: #CCCCCC; // Brightness: 80%
$color-gray-25: #BFBFBF; // Brightness: 75%
$color-gray-30: #B2B2B2; // Brightness: 70%
$color-gray-40: #999999; // Brightness: 60%
$color-gray-65: #595959; // Brightness: 35%
$color-gray-75: #404040; // Brightness: 25%
$color-gray-85: #262626; // Brightness: 15%





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #PRIMARY
\*---------------------------------------------------------------------------*/

$color-primary-200: #6BABDB;
$color-primary-300: #3696D1;
$color-primary-400: #0081C6;
$color-primary-500: #006FAB;
$color-primary-600: #005F91;
$color-primary-700: #004E78;
$color-primary-800: #003D5E;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #FEEDBACK
\*---------------------------------------------------------------------------*/

$color-feedback-success: #559527;
$color-feedback-warning: #E8AE4B;
$color-feedback-danger: #B00020;
$color-feedback-info: #8A4E94;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #HYPERLINKS
\*---------------------------------------------------------------------------*/

$color-link: $color-primary-500;
$color-link-visited: #8A4E94;
$color-link-hover: $color-primary-700;
$color-link-active: #E43E00;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #TYPE
\*---------------------------------------------------------------------------*/

$color-type-body: #000000;


/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #COLOR > #CODE
\*---------------------------------------------------------------------------*/

$code-bg: #F2F2F2;
