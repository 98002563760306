/*---------------------------------------------------------------------------*\
  #BASE > #CODE
\*---------------------------------------------------------------------------*/

code,
kbd,
pre,
samp {
	font-family: $font-family-monospace;
}





/*---------------------------------------------------------------------------*\
  #BASE > #CODE > #INLINE
\*---------------------------------------------------------------------------*/

code {
	border: transparent;
	border-radius: $border-radius-base;
	background-color: $code-bg;
	padding: $space-squish-xxs;
	font-size: 90%;
	color: $code-color;
}


kbd {
	border-radius: $border-radius-s;
	background-color: $kbd-bg;
	padding: 2px 4px;
	font-size: 90%;
	color: $kbd-color;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

	kbd kbd {
		padding: 0;
		font-size: 100%;
		font-weight: bold;
		box-shadow: none;
	}





/*---------------------------------------------------------------------------*\
  #BASE > #CODE > #BLOCK
\*---------------------------------------------------------------------------*/

pre {
	display: block;
	margin: $space-stack-base;
	border-radius: $border-radius-base;
	background-color: $pre-bg;
	padding: $space-squish-base;
	overflow: auto;
	font-size: 90%;
	line-height: $line-height-base;
	color: $pre-color;
}

	/**
	 * Account for some code outputs that place code tags in pre tags.
	 */

	pre > code {
		border: none;
		border-radius: 0;
		background-color: transparent;
		padding: 0;
		font-size: inherit;
		white-space: pre;
		color: inherit;
	}


.pre-scrollable {
	max-height: $pre-scrollable-max-height;
	overflow-y: scroll;
}
