/*---------------------------------------------------------------------------*\
  #BASE > #HTML
\*---------------------------------------------------------------------------*/

/**
 * 1. Set a minimum width so that small devices will not get a strange,
 *    distorted page and rather a page with slight horizontal overhang.
 *
 * 2. Ensure the page always fills at least the entire height of the
 *    viewport.
 *
 * 3. Force scrollbars to always be visible to prevent awkward 'jumps' when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 *
 * 4. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to
 *    exist in ems, the `line-height` is calculated to exist unitlessly.
 *
 * 5. Prevent certain mobile browsers from automatically zooming fonts.
 */

html {
	position: relative;
	min-width: 320px; /* [1] */
	min-height: 100%; /* [2] */
	overflow-y: scroll; /* [3] */
	font-family: $font-family-sans-serif;
	font-size: ($font-size-base / 16px) * 1em; /* [4] */
	line-height: $line-height-base; /* [4] */
	-ms-text-size-adjust: 100%; /* [5] */
	-webkit-text-size-adjust: 100%; /* [5] */

	&.has-active-modal {
		overflow: hidden;
	}
}
