/*---------------------------------------------------------------------------*\
  #COMPONENTS > #ALERTS
\*---------------------------------------------------------------------------*/

@import "bootstrap/alerts";


/**
 * Add an additional state componenet using the primary colours.
 */

.alert-primary {
	@include alert-variant($alert-primary-bg, $alert-primary-border, $alert-primary-text);
}


/**
 * Increase the padding between the dismiss icon and text inside a
 * dismissible alert.
 */

.alert-dismissible {
	padding-right: $alert-padding * 2.5;
}

	.alert-dismissible .close {
		right: -$alert-padding * 1.5;
	}


/**
 * Make links within alerts more “link like”.
 **/

.alert-link {
	text-decoration: underline;
}
