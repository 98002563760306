/*---------------------------------------------------------------------------*\
  #COMPONENTS > #PANELS
\*---------------------------------------------------------------------------*/

@import "bootstrap/panels";


/**
 * Remove the box-shadow from panels.
 */

.panel {
	box-shadow: none;
}
