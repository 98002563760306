/*---------------------------------------------------------------------------*\
  #COMPONENTS > #CARD
\*---------------------------------------------------------------------------*/

.card {
	display: block;
	margin: 10px;
	max-width: 400px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	header {
		position: relative;

		.card-content {
			position: absolute;
			bottom: 0;
			padding: 20px;

			h1 {
				margin: 10px 0;
				font-size: $font-size-xxl;
				font-weight: 200;
				color: $color-white;
			}

			p {
				color: $color-white;
			}
		}

		.card-category {
			display: block;
			background-color: $color-primary-200;
			padding: $space-xs $space-s;
			width: 100%;
			font-size: $font-size-s;
			color: $color-white;
		}

		img {
			width: 100%;
		}
	}

	.card-content {
		padding: 20px;

		p {
			margin-bottom: 0;
			padding-right: 20px;
		}

		button {
			margin-top: 24px;
		}

	}
}

.card.card-link a {

	color: $color-black;

	&:hover,
	&:focus {

		cursor: pointer;
		text-decoration: none;

		.card-content {
			background-color: darken($color-white, 5%);
		}

		header {

			img {
				opacity: 0.75;
			}

			.card-content {

				background-color: transparent;

			}
		}

	}

}

.card.primary {
	background-color: $color-primary-500;

	.card-content {

		h1,
		p {
			color: $color-white;
		}
	}

	a:hover {

		.card-content {

			background-color: $color-primary-200;

			h1,
			p {
				color: $color-black;
			}
		}
	}
}
