/*---------------------------------------------------------------------------*\
#COMPONENTS > #PROCESS STEPS
\*---------------------------------------------------------------------------*/

.process-steps {
	position: relative;
	margin: 0;
	padding: 0;
	list-style-type: none;
	counter-reset: step;

	& > li {
		position: relative;
		clear: both;
		margin-bottom: 0;
		min-height: 124px;
	}

	& > li::before {
		display: block;
		position: absolute;
		top: 0;
		left: 20px;
		z-index: 0;
		border-left: 5px solid $color-gray-20;
		height: 100%;
		content: "";
	}

	ol.process-steps-inner:last-child {
		padding: 24px 24px 0 0;
	}

	.process-steps-title {
		display: block;
		position: relative;
		float: left;
		z-index: 1;
		margin-right: 24px;
		background-color: $color-primary-500;
		padding: 16px 20px;
		width: 100%;
		text-align: left;
		font-size: $font-size-l;
		font-weight: 600;
		color: $color-white;
	}

	.process-steps-inner {
		float: left;
		margin-left: 12px;
		padding: 24px;
		padding-left: 0;
		list-style-position: inside;
	}

	.process-steps-inner {
		list-style-position: outside;

		& > li {
			margin-bottom: 22px;
			list-style-type: none;
		}

		& > li:last-child {
			margin-bottom: 0;
		}
	}

	.process-steps-inner-title {
		display: block;
		position: relative;
		padding-left: 36px;
		font-size: $font-size-m;
		font-weight: 600;
	}

	.process-steps-inner-title::before {
		display: block;
		position: absolute;
		top: -6px;
		left: -8px;
		margin-right: 12px;
		border-radius: 50%;
		background-color: $color-primary-300;
		width: 36px;
		box-sizing: border-box;
		counter-increment: step;
		height: 36px;
		text-align: center;
		font-weight: 600;
		line-height: 38px;
		color: $color-white;
		content: counter(step);
	}

	.process-steps-inner-body {
		display: block;
		padding: 16px;
		padding-left: 56px;

		& > ol,
		& > ul {
			padding-left: 0;
		}
	}
}
