/*---------------------------------------------------------------------------*\
  #UTILITIES > #TYPE
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #UTILITIES > #TYPE > #ALIGNMENT
\*---------------------------------------------------------------------------*/

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}





/*---------------------------------------------------------------------------*\
  #UTILITIES > #TYPE > #TRANSFORM
\*---------------------------------------------------------------------------*/

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}
