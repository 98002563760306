/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #Z-INDEX
\*---------------------------------------------------------------------------*/

$z-outer-space: 10000;
$z-modal: 9000;
$z-modal-background: 8050;
$z-overlay: 8000;
$z-navbar-fixed: 7000;
$z-dropdown: 6000;
$z-popup: 5000;
$z-navbar: 4000;
$z-above: 1;
$z-default: 0;
$z-below: -1;
$z-deepdive: -99999;
