/*---------------------------------------------------------------------------*\
  #GENERIC > #RESET
\*---------------------------------------------------------------------------*/

/**
 * Set the global 'box-sizing' state to 'inherit'.
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

html {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
	box-sizing: inherit;
}


/**
 * Choose to inherit fonts for relevant elements.
 */

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}


/**
 * Collapse table borders.
 */

table {
	border-collapse: collapse;
	border-spacing: 0;
}


/**
 * Override Bootstrap's `.sr-only` class to include APNIC's own
 * `sr-only` mixin.
 */

.sr-only {
	@include sr-only();
}

.sr-only-focusable {

	&:active,
	&:focus {
		@include sr-only();
	}
}


/**
 * iOS "clickable elements" fix for role="button", taken from
 * `bootstrap/scaffolding`.
 *
 * Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
 * for traditionally non-focusable elements with role="button"
 * see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
 */

[role="button"] {
	cursor: pointer;
}
