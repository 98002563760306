/*---------------------------------------------------------------------------*\
  #COMPONENTS > #NAVS
\*---------------------------------------------------------------------------*/

@import "bootstrap/navs";


/**
 * Extend Bootstrap's nav menu item styling to include the use of a
 * class-based approach, to allow for true disabling of dropdown menu items.
 *
 * Styles below are an exact copy from
 * `bootstrap-sass/assets/stylesheets/bootstrap/_navs.scss`.
 */

.nav > li > .nav-item {
	display: block;
	position: relative;
	padding: $nav-link-padding;

	&:hover,
	&:focus {
		background-color: $nav-link-hover-bg;
		text-decoration: none;
	}
}


/**
 * Disabled state sets text to gray and nukes hover/tab effects.
 */

.nav > .disabled > .nav-item {
	color: $nav-disabled-link-color;

	&:hover,
	&:focus {
		background-color: transparent;
		cursor: $cursor-disabled;
		text-decoration: none;
		color: $nav-disabled-link-hover-color;
	}
}


/**
 * Open dropdowns.
 */

.nav .open > .nav-item {

	&,
	&:hover,
	&:focus {
		border-color: $link-color;
		background-color: $nav-link-hover-bg;
	}
}

.nav-tabs > li > .nav-item {
	margin-right: 2px;
	border: 1px solid transparent;
	border-radius: $border-radius-base $border-radius-base 0 0;
	line-height: $line-height-base;

	&:hover {
		border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
	}
}


/**
 * Override the active state, and its :hover to override the normal :hover.
 */

.nav-tabs > .active > a {

	&,
	&:hover,
	&:focus {
		border: 1px solid $nav-tabs-active-link-hover-border-color;
		border-bottom-color: transparent;
		background-color: $nav-tabs-active-link-hover-bg;
		cursor: default;
		color: $nav-tabs-active-link-hover-color;
	}
}

.nav-pills > .active > .nav-item {

	&,
	&:hover,
	&:focus {
		background-color: $nav-pills-active-link-hover-bg;
		color: $nav-pills-active-link-hover-color;
	}
}

.nav-tabs-justified > .active > .nav-item {
	border: 1px solid $nav-tabs-justified-link-border-color;

	&:hover {
		border-bottom-color: $nav-tabs-justified-link-border-color;
	}

	@media (min-width: $screen-s-min) {
		border-bottom-color: $nav-tabs-justified-active-link-border-color;
	}
}


/**
 * Make minor stylistic tweaks to nav items.
 */

.nav-pills > li > .nav-item {
	border-radius: $nav-pills-border-radius;
}

.nav-justified > li > .nav-item {
	margin-bottom: 5px;
	text-align: center;

	@media (min-width: $screen-s-min) {
		margin-bottom: 0;
	}
}


/**
 * Override margin from .nav-tabs.
 */

.nav-tabs-justified > li > .nav-item {
	margin-right: 0;
	border-radius: $border-radius-base;

	&:hover {
		border-bottom-color: transparent;
	}

	@media (min-width: $screen-s-min) {
		border-bottom: 1px solid $nav-tabs-justified-link-border-color;
		border-radius: $border-radius-base $border-radius-base 0 0;
	}
}


/**
 * add new nav class .nav-tabs-topline
 */

.nav-tabs-topline {

	li {

		border-top: 3px solid $color-gray-20;
		background-color: $color-gray-05;
		padding: 3px 0;

		&.active {

			border-top: 3px solid $color-feedback-success;

			&::after {
				background-color: $color-feedback-success;
			}
		}

		&:hover {

			text-decoration: underline;
		}

		a:visited {

			color: transparent;
		}

		&::after {

				display: block;
				position: absolute;
				bottom: -8px;
		    left: 50%;
				z-index: 1;
		    margin-left: -9px;
				border-radius: 50% 50% 0 50%;
				background-color: $color-gray-20;
		    width: 18px;
		    height: 18px;
		    content: "";
		    transform: rotate(45deg);
		}
	}
}
