/*---------------------------------------------------------------------------*\
  #BASE > #IMAGES
\*---------------------------------------------------------------------------*/

/**
 * 1. Fluid images for responsive purposes.
 *
 * 2. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 *
 * 3. Offset `alt` text from surrounding copy.
 */

img {
	max-width: 100%; /* [1] */
	vertical-align: bottom; /* [2] */
	font-style: italic; /* [3] */
}


/**
 * If a `width` and/or `height` attribute have been explicitly defined, let’s
 * not make the image fluid.
 */

img[width],
img[height] {
	max-width: none;
}
