/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #COLORS
\*---------------------------------------------------------------------------*/

$gray-lighter:  $color-gray-05;
$gray-light:    $color-gray-10;
$gray:          $color-gray-25;
$gray-base:     $color-gray-65;
$gray-dark:     $color-gray-75;
$gray-darker:   $color-gray-85;

$brand-primary: $color-primary-500;
$brand-success: $color-feedback-success;
$brand-warning: $color-feedback-warning;
$brand-danger:  $color-feedback-danger;
$brand-info:    $color-feedback-info;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #SCAFFOLDING
\*---------------------------------------------------------------------------*/

$body-bg: $color-white;
$text-color: $color-type-body;

$link-color: $color-link;
$link-visited-color: $color-link-visited; // Custom variable for visited links.
$link-hover-color: $color-link-hover;

$link-hover-decoration: underline;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #TYPOGRAPHY
\*---------------------------------------------------------------------------*/

$font-family-sans-serif: "Whitney SSm A", "Whitney SSm B", "Arial", sans-serif;

$font-size-base:  $font-size-m;
$font-size-large: $font-size-l;
$font-size-small: $font-size-s;

$font-size-h1: $font-size-xxxl;
$font-size-h2: $font-size-xxl;
$font-size-h3: $font-size-xl;
$font-size-h4: $font-size-l;
$font-size-h5: $font-size-m;
$font-size-h6: $font-size-s;

$font-weight:      400;
$font-weight-bold: 600;

$line-height-base: 1.5;
$line-height-computed: $font-size-base * $line-height-base;

$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;

$headings-color: inherit;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #COMPONENTS
\*---------------------------------------------------------------------------*/

/**
 * Padding within components.
 */

$padding-small-vertical: $space-xxxs;
$padding-small-horizontal: $space-s;

$padding-base-vertical: $space-xs;
$padding-base-horizontal: $space-m;

$padding-large-vertical: $space-m;
$padding-large-horizontal: $space-l;


/**
 * Line height variations.
 */

$line-height-large: 1.2;
$line-height-small: 1.875;


/**
 * Border radius variations.
 */

$border-radius-small: $border-radius-s;
$border-radius-base:  $border-radius-m;
$border-radius-large: $border-radius-l;


/**
 * Global color and background color for active items in components, e.g.
 * navs and dropdowns.
 */

$component-active-color: $color-white;
$component-active-bg:    $brand-primary;


/**
 * Width of the `border` used to generate the caret indicators, i.e.
 * tiny triangles, for dropdowns.
 */

$caret-width-base:  4px;
$caret-width-large: 5px;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #TABLES
\*---------------------------------------------------------------------------*/

/**
 * Customizes the `.table` component with basic values, each used across all
 * table variations.
 */

$table-cell-padding:           $padding-base-vertical;
$table-condensed-cell-padding: $padding-small-vertical;


/**
 * Table background variations.
 */

$table-bg:        transparent;
$table-bg-accent: $gray-lighter;
$table-bg-hover:  $gray-light;
$table-bg-active: $gray-base;

/**
 * Define the global table border color.
 */

$table-border-color: $gray;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #BUTTONS
\*---------------------------------------------------------------------------*/

/**
 * For each of Bootstrap's buttons, define text, background and border color.
 */

$btn-font-weight: normal;
$btn-default-color:  $gray-darker;
$btn-default-bg:     $color-white;
$btn-default-border: $gray;


/**
 * Define button state styles.
 */

$btn-primary-color:  $color-white;
$btn-primary-bg:     $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-success-color:  $color-white;
$btn-success-bg:     $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color:     $color-white;
$btn-info-bg:        $brand-info;
$btn-info-border:    darken($btn-info-bg, 5%);

$btn-warning-color:  $color-black;
$btn-warning-bg:     $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color:   $color-white;
$btn-danger-bg:      $brand-danger;
$btn-danger-border:  darken($btn-danger-bg, 5%);

$btn-link-disabled-color: $gray-light;


/**
 * Allows for customizing button radius independently from global
 * border radius.
 */

$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-base;
$btn-border-radius-small: $border-radius-base;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #FORMS
\*---------------------------------------------------------------------------*/

/**
 * Define backgrounds, text and border colors for inputs.
 */
$input-bg: $color-white;
$input-bg-disabled: $gray-lighter;
$input-color: $gray-darker;
$input-color-placeholder: $gray-dark;
$input-border: $gray-base;
$input-border-focus: $brand-primary;


/**
 * Input group specific colors.
 */

$input-group-addon-bg: $gray-lighter;
$input-group-addon-border-color: $input-border;


/**
 * Border radius variations
 */

$input-border-radius: $border-radius-base;
$input-border-radius-large: $border-radius-large;
$input-border-radius-small: $border-radius-small;


/**
 * Line height variations.
 */

$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);


/**
 * Form group styles.
 */

$form-group-margin-bottom: 15px;


/**
 * Fieldset legend styles.
 */

$legend-color: $gray-dark;
$legend-border-color: $gray;


/**
 * Specify a cursor for disabled elements.
 */

$cursor-disabled: not-allowed;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #DROPDOWNS
\*---------------------------------------------------------------------------*/

/**
 * Dropdown menu container and contents.
 */

$dropdown-bg: $color-white;
$dropdown-border: $gray;
$dropdown-fallback-border: $color-gray-20;
$dropdown-divider-bg: $color-gray-10;


/**
 * Link styling withing dropdowns.
 */

$dropdown-link-color: $gray-darker;
$dropdown-link-hover-color: $gray-darker;
$dropdown-link-hover-bg: $gray-light;
$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-disabled-color: $gray;


/**
 * Dropdown header styling.
 */
$dropdown-header-color: $gray-base;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #Z-INDEX-MASTER-LIST
\*---------------------------------------------------------------------------*/

/**
 * Warning: Avoid customizing these values. They're used for a bird's eye view
 * of components dependent on the z-axis and are designed to all work together.
 *
 * Note: These variables are not generated into the Customizer.
 */

$zindex-navbar: $z-navbar;
$zindex-dropdown: $z-dropdown;
$zindex-popover: $z-popup;
$zindex-tooltip: $z-popup;
$zindex-navbar-fixed: $z-navbar-fixed;
$zindex-modal-background: $z-modal-background;
$zindex-modal: $z-modal;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #MEDIA-QUERIES
\*---------------------------------------------------------------------------*/

/**
 * Some customised values have been included to align with APNIC Web Style
 * Guide's naming conventions; in particular, the way sizing suffixes are
 * used, i.e. instead of `sm`, `s` is used (like shirt sizes).
 */

/**
 * Extra small screen / phone.
 * Custom screen size for very small screens.
 */

$screen-xs: 448px;
$screen-xs-min: $screen-xs;


/**
 * Small screen / tablet.
 * Deprecated `$screen-sm` as of v3.0.1.
 */

$screen-sm: 720px;
$screen-sm-min: $screen-sm;
$screen-s-min: $screen-sm-min;


/**
 * Medium screen / desktop.
 * Deprecated `$screen-md` as of v3.0.1.
 */

$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-m-min: $screen-md-min;


/**
 * Large screen / wide desktop.
 * Deprecated `$screen-lg` as of v3.0.1.
 */

$screen-lg: 1280px;
$screen-lg-min: $screen-lg;
$screen-l-min: $screen-lg-min;


/**
 * Custom, maximum screen values.
 */

$screen-xs-max: ($screen-xs-min - 1);
$screen-s-max: ($screen-s-min - 1);
$screen-m-max: ($screen-m-min - 1);
$screen-l-max: ($screen-l-min - 1);





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #GRID-SYSTEM
\*---------------------------------------------------------------------------*/

/**
 * Settings used when generating Bootstrap’s custom grid.
 */

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-float-breakpoint: $screen-s-min;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #CONTAINER-SIZES
\*---------------------------------------------------------------------------*/

/**
 * Define the maximum width of `.container` for different screen sizes.
 */

$container-tablet: (720px + $grid-gutter-width);
$container-sm: $container-tablet;

$container-desktop: (940px + $grid-gutter-width);
$container-md: $container-desktop;

$container-large-desktop: (1140px + $grid-gutter-width);
$container-lg: $container-large-desktop;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #NAVS
\*---------------------------------------------------------------------------*/

/**
 * Define padding and colors for nav links.
 */

$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;
$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;


/**
 * Define colors for nav tabs.
 */

$nav-tabs-border-color: $gray;
$nav-tabs-link-hover-border-color: $gray-lighter;
$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $color-black;
$nav-tabs-active-link-hover-border-color: $gray;
$nav-tabs-justified-link-border-color: $gray;
$nav-tabs-justified-active-link-border-color: $body-bg;


/**
 * Define colors for nav pills.
 */

$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #PAGINATION
\*---------------------------------------------------------------------------*/

$pagination-color: $link-color;
$pagination-bg: $color-white;
$pagination-border: $gray;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: $gray;

$pagination-active-color: $color-white;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray;
$pagination-disabled-bg: $gray-lighter;
$pagination-disabled-border: $gray;

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 0;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #FORM-STATES-AND-ALERTS
\*---------------------------------------------------------------------------*/

/**
 * Define colors for form feedback states and, by default, alerts.
 */

$state-success-text: $color-white;
$state-success-bg: $brand-success;
$state-success-border: $state-success-bg;

$state-info-text: $color-white;
$state-info-bg: $brand-info;
$state-info-border: $state-info-bg;

$state-warning-text: $color-black;
$state-warning-bg: $brand-warning;
$state-warning-border: $state-warning-bg;

$state-danger-text: $color-white;
$state-danger-bg: $brand-danger;
$state-danger-border: $state-danger-bg;

$state-primary-text: $color-white;
$state-primary-bg: $brand-primary;
$state-primary-border: $state-primary-bg;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #TOOLTIPS
\*---------------------------------------------------------------------------*/

$tooltip-max-width: 200px;
$tooltip-color: $color-white;
$tooltip-bg: $color-black;
$tooltip-opacity: 0.9;

$tooltip-arrow-width: 5px;
$tooltip-arrow-color: $tooltip-bg;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #POPOVERS
\*---------------------------------------------------------------------------*/

$popover-bg: $color-white;
$popover-max-width: 276px;
$popover-border-color: rgba(0, 0, 0, 0.2);
$popover-fallback-border-color: $gray;

$popover-title-bg: darken($popover-bg, 3%);

$popover-arrow-width: 10px;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-width: ($popover-arrow-width + 1);
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05);
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%);





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #LABELS
\*---------------------------------------------------------------------------*/

/**
 * Label state background colors.
 */

$label-default-bg: $gray-base;
$label-primary-bg: $brand-primary;
$label-success-bg: $brand-success;
$label-info-bg: $brand-info;
$label-warning-bg: $brand-warning;
$label-danger-bg: $brand-danger;

/**
 * Label text colors.
 */

$label-color: $color-white;
$label-link-hover-color: $color-white;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #MODALS
\*---------------------------------------------------------------------------*/

$modal-inner-padding: 15px;

$modal-title-padding: 15px;
$modal-title-line-height: $line-height-base;

$modal-content-bg: $color-white;
$modal-content-border-color: rgba(0, 0, 0, 0.2);
$modal-content-fallback-border-color: $gray-base;

$modal-backdrop-bg: $color-black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: #E5E5E5;
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #ALERTS
\*---------------------------------------------------------------------------*/

/**
 * Define alert colors, border radius, and padding.
 */

$alert-padding: $padding-base-horizontal;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: $font-weight-bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $brand-info;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

/**
 * Customized alert using brand colors.
 */

$alert-primary-bg: $state-primary-bg;
$alert-primary-text: $state-primary-text;
$alert-primary-border: $state-primary-border;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #PROGRESS-BAR
\*---------------------------------------------------------------------------*/

/**
 * Define default colors for progress bars.
 */

$progress-bg: $gray-lighter;
$progress-bar-color: $color-white;
$progress-border-radius: $border-radius-base;

/**
 * Define state colors for progress bars.
 */

$progress-bar-bg: $brand-primary;
$progress-bar-success-bg: $brand-success;
$progress-bar-warning-bg: $brand-warning;
$progress-bar-danger-bg: $brand-danger;
$progress-bar-info-bg: $brand-info;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #LIST-GROUP
\*---------------------------------------------------------------------------*/

$list-group-bg: $color-white;
$list-group-border: $gray;
$list-group-border-radius: $border-radius-base;

$list-group-hover-bg: $gray-lighter;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border: $list-group-active-bg;
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

$list-group-disabled-color: $gray-light;
$list-group-disabled-bg: $gray-lighter;
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: $gray-dark;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: $gray-darker;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #PANELS
\*---------------------------------------------------------------------------*/

$panel-bg: $color-white;
$panel-body-padding: $space-inset-l;
$panel-heading-padding: $space-squish-base;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

$panel-inner-border: $gray;
$panel-footer-bg: $gray-lighter;

/**
 * Define state colors for panels.
 */

$panel-default-text: $gray-darker;
$panel-default-border: $gray;
$panel-default-heading-bg: $gray-lighter;

$panel-primary-text: $color-white;
$panel-primary-border: $brand-primary;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: $state-success-text;
$panel-success-border: $state-success-bg;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $state-info-bg;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-bg;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-bg;
$panel-danger-heading-bg: $state-danger-bg;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #THUMBNAILS
\*---------------------------------------------------------------------------*/

$thumbnail-padding: 0;
$thumbnail-bg: $body-bg;
$thumbnail-border: $gray;
$thumbnail-border-radius: $border-radius-base;

$thumbnail-caption-color: $text-color;
$thumbnail-caption-padding: $space-m;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #WELLS
\*---------------------------------------------------------------------------*/

$well-bg: $gray-lighter;
$well-border: $gray;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #BADGES
\*---------------------------------------------------------------------------*/

$badge-color: $color-white;
$badge-link-hover-color: $color-white;
$badge-bg: $gray-darker;

$badge-active-color: $link-color;
$badge-active-bg: $color-white;

$badge-font-weight: $font-weight-bold;
$badge-line-height: 1;
$badge-border-radius: $space-xs;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #BREADCRUMBS
\*---------------------------------------------------------------------------*/

$breadcrumb-padding-vertical: $space-s;
$breadcrumb-padding-horizontal: $space-m;
$breadcrumb-bg: $gray-lighter;
$breadcrumb-color: $gray-dark;
$breadcrumb-active-color: $gray-darker;
$breadcrumb-separator: "/";





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #CODE
\*---------------------------------------------------------------------------*/

$code-color: $gray-dark;
$code-bg: $gray-light;

$kbd-color: $color-white;
$kbd-bg: $gray-dark;

$pre-bg: $gray-lighter;
$pre-color: $gray-dark;
$pre-border-color: $gray;
$pre-scrollable-max-height: 340px;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #OVERRIDES > #TYPE
\*---------------------------------------------------------------------------*/

$component-offset-horizontal: 180px;
$text-muted: $gray-light;
$abbr-border-color: $gray-light;
$headings-small-color: $gray-light;
$blockquote-small-color: $gray-light;
$blockquote-font-size: ($font-size-base * 1.25);
$blockquote-border-color: $gray-lighter;
$page-header-border-color: $gray-lighter;
$dl-horizontal-offset: $component-offset-horizontal;
$dl-horizontal-breakpoint: $grid-float-breakpoint;
$hr-border: $gray-lighter;
