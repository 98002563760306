/*---------------------------------------------------------------------------*\
  #COMPONENTS > #BUTTONS
\*---------------------------------------------------------------------------*/

/**
 * Define button specific variables.
 */

$btn-primary-border: transparent;
$btn-info-border: transparent;
$btn-success-border: transparent;
$btn-warning-border: transparent;
$btn-danger-border: transparent;

@import "bootstrap/buttons";
@import "bootstrap/button-groups";


/**
 * .btn-sm and .btn-lg are Bootstrap classes but .btn-s and .btn-l are
 * prefered.
 */

.btn-s {
	@extend .btn-sm;
}

.btn-l {
	@extend .btn-lg;
}


/**
 * Define the breakpoints at which block buttons will toggle their full width
 * appearance.
 */

.btn-block-xs,
.btn-block-s,
.btn-block-m,
.btn-block-l {
	width: 100%;
}

@media screen and (min-width: $screen-xs-min) {

	.btn-block-xs {
		width: auto;
	}
}

@media screen and (min-width: $screen-s-min) {

	.btn-block-s {
		width: auto;
	}
}

@media screen and (min-width: $screen-m-min) {

	.btn-block-m {
		width: auto;
	}
}

@media screen and (min-width: $screen-l-min) {

	.btn-block-l {
		width: auto;
	}
}
