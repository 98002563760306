/*---------------------------------------------------------------------------*\
  #COMPONENTS > #PROGRESS-BARS
\*---------------------------------------------------------------------------*/

@import "bootstrap/progress-bars";


/**
 * Set a minimum width to avoid declaring it manually via the `style`
 * attribute.
 */

.progress-bar {
	min-width: 2em;
}
