/*---------------------------------------------------------------------------*\
  #UTILITIES > #UTILITIES
\*---------------------------------------------------------------------------*/

@import "bootstrap/utilities";


/*---------------------------------------------------------------------------*\
  #UTILITIES > #STATIC
\*---------------------------------------------------------------------------*/

.is-static {
    display: block;
    position: relative;
}
