/*---------------------------------------------------------------------------*\
  #UTILITIES > #SPACE
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #UTILITIES > #SPACE > #MARGIN
\*---------------------------------------------------------------------------*/

@include space("margin", "xs", $space-xs / 4);
@include space("margin", "s", $space-s / 2);
@include space("margin", null, $space-base);
@include space("margin", "l", $space-l * 2);
@include space("margin", "xl", $space-xl * 4);
@include space("margin", "none", 0);





/*---------------------------------------------------------------------------*\
  #UTILITIES > #SPACE > #PADDING
\*---------------------------------------------------------------------------*/

@include space("padding", "xs", $space-xs / 4);
@include space("padding", "s", $space-s / 2);
@include space("padding", null, $space-base);
@include space("padding", "l", $space-l * 2);
@include space("padding", "xl", $space-xl * 4);
@include space("padding", "none", 0);
