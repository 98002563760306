/*---------------------------------------------------------------------------*\
  #COMPONENTS > #LABELS
\*---------------------------------------------------------------------------*/

@import "bootstrap/labels";


.label-warning {
	color: $gray-darker;
}
