/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #TYPE
\*---------------------------------------------------------------------------*/

/**
 * Based on a modular scale where:
 * Base = 16px
 * Ratio = 1.125
 */

$font-size-xs: 11.237px;
$font-size-s: 14.222px;
$font-size-m: 16px;
$font-size-l: 20.25px;
$font-size-xl: 25.629px;
$font-size-xxl: 32.437px;
$font-size-xxxl: 41.053px;
$font-size-base: $font-size-m;
