/*---------------------------------------------------------------------------*\
  #COMPONENTS > #DROPDOWNS
\*---------------------------------------------------------------------------*/

@import "bootstrap/dropdowns";


/**
 * Make minor modifications to Bootstrap's dropdown menu for visual
 * aesthetic purposes.
 */

.dropdown-menu {
	margin: 0;
	margin-top: -1px;
	padding: 0;
}

.dropdown-header {
	padding: $space-squish-m;
}

.dropup .dropdown-menu {
	margin-top: 0;
	margin-bottom: -1px;
}


/**
 * Extend Bootstrap's dropdown menu item styling to include the use of a
 * class-based approach, to allow for true disabling of dropdown menu items.
 *
 * Styles below are an exact copy from
 * `bootstrap-sass/assets/stylesheets/bootstrap/_dropdowns.scss`.
 */

.dropdown-menu > li > .dropdown-item {
	display: block;
	clear: both;
	padding: $space-xs $space-m;
	font-weight: normal;
	line-height: $line-height-base;
	white-space: nowrap;
	color: $dropdown-link-color;
}

.dropdown-menu > .disabled > .dropdown-item {

	&,
	&:hover,
	&:focus {
		text-decoration: line-through;
		color: $dropdown-link-disabled-color;
	}

	// Nuke hover/focus effects

	&:hover,
	&:focus {
		@include reset-filter();

		background-color: transparent;
		background-image: none; // Remove CSS gradient
		cursor: $cursor-disabled;
		text-decoration: line-through;
	}
}
