/*---------------------------------------------------------------------------*\
  #COMPONENTS > #WELLS
\*---------------------------------------------------------------------------*/

@import "bootstrap/wells";


/**
 * Improve the padding and font sizing of the well and its sizing modifiers.
 */

.well {
	margin: 20px 0;
	border: none;
	padding: $space-inset-base;
	box-shadow: none;
}

.well-code {
	margin-bottom: 0;
	border: none;
	border-radius: 0;
	background-color: #F2F2F2;
	padding: 16px;
	box-shadow: none;
	min-height: 20px;
}


/*---------------------------------------------------------------------------*\
  #COMPONENTS > #WELLS > #SIZING
\*---------------------------------------------------------------------------*/

.well-l {
	padding: $space-inset-l;
}

.well-s {
	padding: $space-inset-base;
	font-size: $font-size-small;
}
