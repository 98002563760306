/*---------------------------------------------------------------------------*\
  #COMPONENTS > #POPOVERS
\*---------------------------------------------------------------------------*/

@import "bootstrap/popovers";


.popover-title {
	padding: $space-inset-s;
}

.popover-content {
	padding: $space-inset-base;
}
