/*---------------------------------------------------------------------------*\
  #COMPONENTS > #MEDIA
\*---------------------------------------------------------------------------*/

@import "bootstrap/media";


/**
 * Fixes issue that occurs when enabling responsive images by default
 * via `max-width: 100%;` declaration in `base/images`.
 */

.media-object {
	max-width: none;
}


/**
 * Adjust the padding of media objects.
 */

.media-right,
.media > .pull-right {
	padding-left: $space-l;
}

.media-left,
.media > .pull-left {
	padding-right: $space-l;
}

.media-heading {
	margin: $space-stack-s;
}
