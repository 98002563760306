/*---------------------------------------------------------------------------*\
  #COMPONENTS > #BADGES
\*---------------------------------------------------------------------------*/

@import "bootstrap/badges";


/**
 * Add spacing between the label and badge indicator.
 */

.badge {
	margin-left: $space-inline-s;
}
