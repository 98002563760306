/*---------------------------------------------------------------------------*\
  #OBJECTS > #FOOTER
\*---------------------------------------------------------------------------*/

footer.footer {
	position: absolute;
    bottom: 0;
	margin-top: 50px;
	background-color: $color-black;
	padding: 16px;
	width: 100%;
	font-size: 14.222px;
	color: $color-white;

	.size-1-of-4,
	.size-3-of-4 {
		vertical-align: middle;

		@media screen and (max-width: 719px) {
    width: 100% !important;
		}
	}

	.footer-logo {
    margin: 0;
	background: url("images/apnic-reverse.svg") no-repeat;
	background-size: auto 28px;
	height: 25px;

		@media screen and (max-width: 719px) {
		margin: 0 0 16px 0;
	    margin-right: auto;
	    margin-left: auto;
		background-size: 100px 28px;
	    max-width: 100px;
		}
	}

	a.fa {
		text-decoration: none;
		color: $color-white;

		&:hover {
			color: $color-primary-200;
		}
	}

	.footer-social {
		text-align: right;

		@media screen and (max-width: 719px) {
			width: 100%;
			text-align: center;
	    font-size: 14px;
		}

		ul {
	    display: inline-block;
	    margin: 0 0 0 16px;
			padding: 0;
			list-style-type: none;
			vertical-align: middle;

			@media screen and (max-width: 719px) {
				margin: 10px 0 0 0;
		    width: 100%;
		    font-size: 20px;
			}

			li {
				display: inline-block;

				a {
					display: block;
					width: 30px;
					color: $color-white;
				}
			}
		}
	}

	.footer-aside {
		clear: both;
    margin: 16px 0 8px;
    border-top: 1px solid $color-gray-10;
		padding: 16px 0;

		.footer-aside-copyright {
	    float: left;
	    margin: 0 0 16px 0;
	    text-align: left;

			@media screen and (max-width: 719px) {
    			width: 100%;
					text-align: center;
			}

			.footer-aside-copyright-abn {
		    display: inline-block;
		    margin-left: 16px;
			}
		}

		.footer-aside-links {
	    float: right;
	    margin: 0;
			padding: 0;
			max-width: none;
			list-style-type: none;

			@media screen and (max-width: 719px) {
				float: none;
				margin: 0 auto;
				max-width: 250px;
		    text-align: center;
			}

			li {
				display: inline-block;
    		margin: 0;
    		padding: 0 0 0 15px;

				a {
					color: $color-white;
				}
			}
		}
	}
}
