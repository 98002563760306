/*---------------------------------------------------------------------------*\
  #COMPONENTS > #FORMS
\*---------------------------------------------------------------------------*/

@import "bootstrap/forms";
@import "bootstrap/input-groups";


/**
 * Update the validation states for form controls.
 */

.has-success {
	@include form-control-validation($state-success-bg, $state-success-border, $state-success-bg);
}

.has-warning {
	@include form-control-validation($state-warning-bg, $state-warning-border, $state-warning-bg);
}

.has-error {
	@include form-control-validation($state-danger-bg, $state-danger-border, $state-danger-bg);
}


/**
 * Overriding styling for common form elements.
 */

label {
	margin: $space-stack-s;
}

fieldset {
	margin: $space-stack-base;
	border: 1px solid $gray-base;
	padding: $space-inset-base;
}

legend {
	margin: 0;
	border: 0;
	padding: 0 $space-s;
	width: auto;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	color: $text-color;
}


/**
 * Overriding styling for Bootstrap's form-control class.
 */

.form-control {
	padding: $space-squish-s;
}

/**
 * .form-group-lg is a Bootstrap class but .form-group-l is prefered.
 */

.form-group-lg .form-control {
	padding: $space-squish-m;
}

.form-group-l {
	@extend .form-group-lg;
}

/**
 * .form-group-sm is a Bootstrap class but .form-group-s is prefered.
 */

.form-group-s {
	@extend .form-group-sm;
}

/**
 * Customize controls with the `readonly` attribute to look like static text,
 * for accessibility.
 */

.form-control[readonly] {
	border: none;
	background-color: transparent;
	padding-right: 0;
	padding-left: 0;
	color: $color-black;
	box-shadow: none;
}


/**
 * Adjust help block spacing.
 */

.help-block {
	margin: 0;
	margin-top: $space-s;
}


/**
 * Extension to the help block component, help hints display below labels and
 * are typically used to demonstrate examples. They are a recommended
 * alternative to using placeholder text.
 */

.help-hint {
	display: block;
	font-size: $font-size-small;
	font-weight: $font-weight;
}


/**
 * Fix up the vertical spacing with checkboxes and radio buttons.
 */

.radio,
.checkbox {
	margin: $space-stack-s;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: 0;
}

.radio:last-of-type,
.checkbox:last-of-type {
	margin-bottom: 0;
}
