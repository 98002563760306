/*---------------------------------------------------------------------------*\
  #BASE > #LINKS
\*---------------------------------------------------------------------------*/

a {
	text-decoration: none;
	color: $link-color;
}

	a:visited {
		color: $link-color;
	}

	a:hover,
	a:focus {
		text-decoration: $link-hover-decoration;
		color: $link-hover-color;
	}

	a:focus {
		@include tab-focus();
	}


	/*---------------------------------------------------------------------------*\
	  #BASE > #LINKS > #ALERT-INFO
	\*---------------------------------------------------------------------------*/

	.alert-info {

		a {
			text-decoration: $link-hover-decoration;
			font-weight: 600;
			color: $color-white;

			&:hover {
				color: $color-white;
			}

			&visited {
				color: $color-white;
			}
		}
	}
