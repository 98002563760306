/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #SPACE
\*---------------------------------------------------------------------------*/

/**
 * Based on a modular scale where:
 * Base = 16px
 * Ratio = 1.5
 */

$space-xxxs: 2.107px;
$space-xxs: 4.741px;
$space-xs: 7.111px;
$space-s: 10.667px;
$space-m: 16px;
$space-l: 24px;
$space-xl: 36px;
$space-xxl: 54px;
$space-base: $space-l;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #SPACE > #INSET
\*---------------------------------------------------------------------------*/

/**
 * Inset space is used when adding a “frame” to the content within components.
 * Insets involve the `padding` CSS property and are applied to block elements.
 */

$space-inset-xs:  $space-xs;
$space-inset-s:   $space-s;
$space-inset-m:   $space-m;
$space-inset-l:   $space-l;
$space-inset-xl:  $space-xxl;
$space-inset-base: $space-inset-m;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #SPACE > #SQUISH
\*---------------------------------------------------------------------------*/

/**
 * Squished space differs slightly to insets by being uneven on either sides,
 * with the top and bottom padding being smaller than the right and left.
 * Squishes involve the `padding` CSS property and are applied to inline
 * elements.
 */

$space-squish-xxs: $space-xxxs $space-xxs;
$space-squish-xs: $space-xxs $space-xs;
$space-squish-s: $space-xs $space-s;
$space-squish-m: $space-s $space-m;
$space-squish-l: $space-m $space-l;
$space-squish-base: $space-squish-m;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #SPACE > #STACK
\*---------------------------------------------------------------------------*/

/**
 * Stacked space is used when “stacking” components on top of one another.
 * Stacks involve the `margin-bottom` CSS property and are applied to block
 * elements.
 */

$space-stack-xxs: 0 0 $space-xxs 0;
$space-stack-xs:  0 0 $space-xs 0;
$space-stack-s:   0 0 $space-s 0;
$space-stack-m:   0 0 $space-m 0;
$space-stack-l:   0 0 $space-l 0;
$space-stack-xl:  0 0 $space-xl 0;
$space-stack-xxl: 0 0 $space-xxl 0;
$space-stack-base: $space-stack-l;





/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #SPACE > #INLINE
\*---------------------------------------------------------------------------*/

/**
 * Inset space is used when displaying multiple inline components side by side.
 * Inset space involves the `margin-left` and `margin-right` CSS properties and
 * are applied to inline elements.
 */

$space-inline-xxs: $space-xxs;
$space-inline-xs:  $space-xs;
$space-inline-s:   $space-s;
$space-inline-m:   $space-m;
$space-inline-l:   $space-l;
$space-inline-xl:  $space-xl;
$space-inline-xxl: $space-xxl;
$space-inline-base: $space-inline-s;
