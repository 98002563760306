/*---------------------------------------------------------------------------*\
  #BASE > #TYPE
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #HEADINGS
\*---------------------------------------------------------------------------*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: $space-stack-base;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;
}

h1 small,
.h1 small,
h1 .small,
.h1 .small,
h2 small,
.h2 small,
h2 .small,
.h2 .small,
h3 small,
.h3 small,
h3 .small,
.h3 .small,
h4 small,
.h4 small,
h4 .small,
.h4 .small,
h5 small,
.h5 small,
h5 .small,
.h5 .small,
h6 small,
.h6 small,
h6 .small,
.h6 .small {
	font-weight: normal;
	line-height: 1;
	color: $headings-small-color;
}

h1,
.h1 {
	font-size: $font-size-h1;
	color: $color-primary-500;
}

h2,
.h2 {
	font-size: $font-size-h2;
	color: $color-primary-700;
}

h3,
.h3 {
	font-size: $font-size-h3;
	color: $color-primary-800;
}

h4,
.h4 {
	font-size: $font-size-h4;
}

h5,
.h5 {
	font-size: $font-size-h5;
}

h6,
.h6 {
	font-size: $font-size-h6;
}

/**
 * Increase the top margin of headings based on their level.
 */

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-top: $space-xl;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: $space-l;
}

/**
 * Reset the top margin of a heading if is the first child of an element.
 */

h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.h6:first-child {
	margin-top: 0;
}





/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #BODY-TEXT
\*---------------------------------------------------------------------------*/

p {
	margin: $space-stack-base;
}

.lead {
	font-size: $font-size-large;
	line-height: 1.4;
}





/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #LISTS
\*---------------------------------------------------------------------------*/

ul,
ol,
dl {
	margin: $space-stack-base;

	ul,
	ol,
	dl {
		margin-bottom: 0;
	}
}

dd {
	margin-left: $space-m;
}

dd + dt {
	margin-top: $space-xs;
}


.list-unstyled {
	padding-left: 0;
    list-style: none;
}


.list-inline {
	margin-left: -$space-xs;
	padding-left: 0;
	list-style: none;
}

	.list-inline > li {
		display: inline-block;
		padding-right: $space-inline-xs;
		padding-left: $space-inline-xs;
	}





/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #BLOCKQUOTE
\*---------------------------------------------------------------------------*/

blockquote {
	@include clear-last-child();

	margin: 0;
	border-left: $border-width-l solid $gray;
	padding-left: $space-base;
}

	blockquote > p {
		margin-bottom: $padding-base-vertical;
	}

	blockquote > footer {
		margin-top: 0;
		background-color: transparent;
		padding: 0;
		font-size: inherit;
		color: $gray-dark;
	}





/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #HORIZONTAL-RULE
\*---------------------------------------------------------------------------*/

hr {
	margin: $space-xl auto;
	border: none;
	border-top: $border-width-base solid $gray-light;
	max-width: em(250px);
}





/*---------------------------------------------------------------------------*\
  #BASE > #TYPE > #INLINE-ELEMENTS
\*---------------------------------------------------------------------------*/

mark {
	background-color: $brand-warning;
	padding: $padding-xs-vertical $padding-xs-horizontal;
}


del {
	text-decoration: line-through;
	color: $gray-dark;
}


s {
	text-decoration: line-through;
	color: $gray-dark;
}


ins {
	text-decoration: underline;
	color: #377834;
}


u {
	text-decoration: underline;
}


small {
	font-size: $font-size-small;
}


b,
strong {
	font-weight: $font-weight-bold;
}


i,
em {
	font-style: italic;
}


cite {
	font-style: italic;
	color: $gray-dark;
}


abbr {
	cursor: help;
}
