/*---------------------------------------------------------------------------*\
  #TOOLS > #MIXINS
\*---------------------------------------------------------------------------*/

/// The most current “clearfix”. Overrides Bootstrap's `clearfix` mixin.
/// @author APNIC
@mixin clearfix {

	&::after {
		display: block !important;
		clear: both !important;
		content: "" !important;
	}
}

/// Removes the bottom margin on the last child of an element.
/// @author APNIC
@mixin clear-last-child {

	> :last-child {
		margin-bottom: 0 !important;
	}
}

/// Hide content, but still make it available to screen readers and other
/// assistive technologies.
/// @author APNIC
/// @require $z-deepdive
@mixin sr-only {
	position: absolute !important;
	z-index: $z-deepdive !important;
	margin: -1px !important;
	border: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	overflow: hidden !important;
	clip-path: rect(0 0 0 0) !important;
}

/// Generate a series of classes that adjust the margin/padding of an element.
/// @author APNIC
/// @param {String} $property - The property to apply classes to..
/// @param {Number} $suffix [null] - The value to use for the space classes.
/// @param {String} $space [0] - A suffix for the space classes, used as a modifier.
@mixin space($property, $suffix: null, $space: 0) {
	// Prepend a "-" to the suffix if it isn’t empty.
	@if $suffix {
		$suffix: "-" + $suffix;
	}

	.#{$property}#{$suffix} {
		#{$property}: $space !important;
	}

	.#{$property}-top#{$suffix} {
		#{$property}-top: $space !important;
	}

	.#{$property}-right#{$suffix} {
		#{$property}-right: $space !important;
	}

	.#{$property}-bottom#{$suffix} {
		#{$property}-bottom: $space !important;
	}

	.#{$property}-left#{$suffix} {
		#{$property}-left: $space !important;
	}

	.#{$property}-vertical#{$suffix} {
		#{$property}-top: $space !important;
		#{$property}-bottom: $space !important;
	}

	.#{$property}-horizontal#{$suffix} {
		#{$property}-right: $space !important;
		#{$property}-left: $space !important;
	}
}

/// Override Bootstrap’s alert-variant mixin (found within
/// <code>bootstrap/mixins/alerts</code>) to prevent the darkening of the text
/// color (for accessibility/contrast purposes).
/// @author Bootstrap
/// @author APNIC
/// @param {Color} $background - The background color of the alert variant.
/// @param {Color} $border - The border color of the alert variant.
/// @param {Color} $text-color - The text color of the alert variant.
@mixin alert-variant($background, $border, $text-color) {
	border-color: transaprent;
	background-color: $background;
	color: $text-color;

	hr {
		border-top-color: darken($border, 5%);
	}

	.alert-link {
		color: $text-color;
	}
}

/// Override Bootstrap’s button-variant mixin (found within
/// <code>bootstrap/mixins/buttons</code>) to produce buttons with additional
/// states and modified styling.
/// @author Bootstrap
/// @author APNIC
/// @param {Color} $color - The text color of the button variant.
/// @param {Color} $background - The background color of the button variant.
/// @param {Color} $border - The border color of the button variant.
@mixin button-variant($color, $background, $border) {
	border-color: $border;
	background-color: $background;
	color: $color;

	&:visited {
		color: $color;
	}

	&:focus,
	&.focus {
		border-color: darken($border, 25%);
		background-color: darken($background, 10%);
		color: $color;
	}

	&:hover {
		border-color: darken($border, 12%);
		background-color: darken($background, 10%);
		color: $color;
	}

	&:active,
	&.active,
	.open > &.dropdown-toggle {
		border-color: darken($border, 12%);
		background-color: darken($background, 10%);
		color: $color;

		&:hover,
		&:focus,
		&.focus {
			border-color: darken($border, 25%);
			background-color: darken($background, 17%);
			color: $color;
		}
	}

	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {

		&:hover,
		&:focus,
		&.focus {
			border-color: $border;
			background-color: $background;
		}
	}

	.badge {
		background-color: $color;
		color: $background;
	}
}

/// Override Bootstrap’s table-row-variant mixin (found within
/// <code>bootstrap/mixins/table-row</code>) to produce lighter backgrounds
/// contextual table rows.
/// @author Bootstrap
/// @author APNIC
/// @param {Color} $state - The class name to use for the table row variant.
/// @param {Color} $background - The background color of the table row variant.
@mixin table-row-variant($state, $background) {
	// Exact selectors below required to override `.table-striped` and prevent
	// inheritance to nested tables.

	.table > thead > tr,

	.table > tbody > tr,

	.table > tfoot > tr {

		> td.#{$state},
		> th.#{$state},
		&.#{$state} > td,
		&.#{$state} > th {
			background-color: scale-color($background, $lightness: 85%);
		}
	}

	// Hover states for `.table-hover`
	// Note: this is not available for cells or rows within `thead` or `tfoot`.

	.table-hover > tbody > tr {

		> td.#{$state}:hover,
		> th.#{$state}:hover,
		&.#{$state}:hover > td,
		&:hover > .#{$state},
		&.#{$state}:hover > th {
			background-color: darken($background, 5%);
		}
	}
}

/// Override Bootstrap’s make-grid mixin (found within
/// <code>bootstrap/mixins/grid-framework</code>) to produce grid sizing
/// classes without the extra bloat.
/// @author Bootstrap
/// @author APNIC
/// @param {String} $size - The size identifier for the generated classes.
@mixin make-grid($size: null) {
	// Add hyphens either side of the size identifier if one has been
	// specified.
	@if $size {
		$size: -#{$size};
	}

	// A single class for full width columns.

	.size#{$size}-1-of-1 {
		width: percentage(1/1) !important;
	}

	// A single class for two column grids.

	.size#{$size}-1-of-2 {
		width: percentage(1/2) !important;
	}

	// Classes for three column grids.

	.size#{$size}-1-of-3 {
		width: percentage(1/3) !important;
	}

	.size#{$size}-2-of-3 {
		width: percentage(2/3) !important;
	}

	// Classes for four column grids.

	.size#{$size}-1-of-4 {
		width: percentage(1/4) !important;
	}

	.size#{$size}-2-of-4 {
		width: percentage(2/4) !important;
	}

	.size#{$size}-3-of-4 {
		width: percentage(3/4) !important;
	}

	// Classes for five column grids.

	.size#{$size}-1-of-5 {
		width: percentage(1/5) !important;
	}

	.size#{$size}-2-of-5 {
		width: percentage(2/5) !important;
	}

	.size#{$size}-3-of-5 {
		width: percentage(3/5) !important;
	}

	.size#{$size}-4-of-5 {
		width: percentage(4/5) !important;
	}

	// Classes for 12-column grids.

	.size#{$size}-1-of-12 {
		width: percentage(1/12) !important;
	}

	.size#{$size}-2-of-12 {
		width: percentage(2/12) !important;
	}

	.size#{$size}-3-of-12 {
		width: percentage(3/12) !important;
	}

	.size#{$size}-4-of-12 {
		width: percentage(4/12) !important;
	}

	.size#{$size}-5-of-12 {
		width: percentage(5/12) !important;
	}

	.size#{$size}-6-of-12 {
		width: percentage(6/12) !important;
	}

	.size#{$size}-7-of-12 {
		width: percentage(7/12) !important;
	}

	.size#{$size}-8-of-12 {
		width: percentage(8/12) !important;
	}

	.size#{$size}-9-of-12 {
		width: percentage(9/12) !important;
	}

	.size#{$size}-10-of-12 {
		width: percentage(10/12) !important;
	}

	.size#{$size}-11-of-12 {
		width: percentage(11/12) !important;
	}

	.size#{$size}-12-of-12 {
		width: percentage(12/12) !important;
	}
}
