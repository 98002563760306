/*---------------------------------------------------------------------------*\
  #OBJECTS > #GRID
\*---------------------------------------------------------------------------*/

/**
 * APNIC uses it's own grid system over Bootstrap's for simplicity reasons;
 * cleaner markup and CSS, flexible rules and multiple grid types allow
 * all kinds of content to be laid out.
 */


/**
 * Define grid specific variables.
 */

$grid-gutter-base: $space-xl;

/**
 * Set the default grid style.
 */

.grid {
	display: block;
	margin: 0;
	padding: 0;
}

	.grid > .col {
		margin: 0;
		padding: 0;
		width: 100%;
	}

/**
 * Use Bootstrap’s grid functions to create grid sizing options. Two suffix
 * options are available for backwards compatability across alpha versions.
 * Longhand sizing suffixes (`sm`/`md`/`lg`) will be deprecated.
 */

@include make-grid();

@media screen and (min-width: $screen-s-min) {
	@include make-grid("s");
	@include make-grid("sm");
}

@media screen and (min-width: $screen-m-min) {
	@include make-grid("m");
	@include make-grid("md");
}

@media screen and (min-width: $screen-l-min) {
	@include make-grid("l");
	@include make-grid("lg");
}





/*---------------------------------------------------------------------------*\
  #OBJECTS > #GRID > #FLOAT
\*---------------------------------------------------------------------------*/

/**
 * Float-based grids are good for single rows of columns, where wrapping
 * will not be a problem.
 */

.grid-float {
	@include clearfix();
}

	.grid-float > .col {
		float: left;
	}

	.grid-float.grid-gutter > .col {
		padding-right: $grid-gutter-base;

		&:last-of-type {
			padding-right: 0;
		}
	}




/*---------------------------------------------------------------------------*\
  #OBJECTS > #GRID > #INLINE
\*---------------------------------------------------------------------------*/

/**
 * Inline-based grids are good for grids with many items that need wrapping,
 * or single-line grids where vertical alignment is important, e.g. footers.
 */

.grid-inline {
	font-size: 0;
}

	.grid-inline > .col {
		display: inline-block;
		vertical-align: top;
		font-size: $font-size-base;
	}

	.grid-inline.grid-gutter {
		margin-left: -$grid-gutter-base;
	}

		.grid-inline.grid-gutter > .col {
			padding-left: $grid-gutter-base;
		}





/*---------------------------------------------------------------------------*\
  #OBJECTS > #GRID > #FLEX
\*---------------------------------------------------------------------------*/

/**
 * Flex-based grids are the most powerful grid CSS has to offer (until CSS
 * grid layout becomes a thing). Use these for complex layouts, but bear in
 * mind that browser support is restricted to modern browsers. Flex-based
 * grids will revert to a single-column display in browsers that do not yet
 * support flexbox.
 */

.grid-flex {
	display: flex;
}

	.grid-flex-row {
		flex-flow: row wrap;
	}

	.grid-flex-col {
		flex-flow: column wrap;
	}

	.col-flex {
			flex: 1;
	}

	.grid-flex-row.grid-gutter {
			margin-left: -$grid-gutter-base;
	}

		.grid-flex-row.grid-gutter > .col {
			padding-left: $grid-gutter-base;
		}
