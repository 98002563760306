/*---------------------------------------------------------------------------*\
  #OBJECTS > #UTILITY BAR
\*---------------------------------------------------------------------------*/

	#global-nav {
		// display: none;

		@media screen and (min-width: $screen-sm-min) {
			display: block;
			background-color: $color-gray-85;
			padding: 0 16px;
			height: auto;
			text-align: right;
			font-size: 12px;
		}

		.header-logo-image-s {
			float: left;
			margin-top: 4px;
			background: url("images/apnic-reverse.svg") no-repeat;
			background-size: 68% auto;
			width: 100px;
			height: 20px;
		}

		.utility {
			display: none;

			@media screen and (min-width: $screen-sm-min) {
				display: inline-block;
				margin-right: -1.5px;
				margin-left: -1.5px;
				vertical-align: middle;
				text-transform: uppercase;
			}
		}

		.utility > a {
			display: block;
			padding: 6px 9px;
			color: $color-white;

			&:hover,
			&:active,
			&:focus {
				background-color: $color-gray-75;
				padding: 6px 9px;
				text-decoration: none;
				color: $color-white;
			}
		}

		.utility > a.btn-login {
		    padding: 6px;

			&.btn-primary:hover,
			&.btn-primary:active,
			&.btn-primary:focus {
				background-color: darken($color-primary-400, 10%);
			}
		}

		.nav .open > a:hover,
		.nav .open > a:focus {
				background-color: $color-gray-75 !important;
				text-decoration: none;
				color: $color-white;
		}

		.dropdown {
			background-color: transparent;

			&:hover {
				background-color: $color-gray-75;
				cursor: pointer;
			}

			a.dropdown-toggle {
				border: none;
				background-color: transparent;
				padding: 6px 10px;
				color: white;
			}

			a.dropdown-toggle-menu {
				border: none;
				background-color: transparent;
				padding-top: 2px;
				padding-bottom: 2px;

				i {
					color: $color-white;
				}
			}

			ul.dropdown-menu {
				z-index: $z-outer-space;
				margin-top: 0;
				border: none;
				background-color: $color-gray-05;
				font-size: 12px;

				li {

					a {
						padding: 6px 20px;
						color: $color-gray-85;

						&:hover {
							background-color: $color-gray-10;
						}
					}
				}
			}
		}

		#search.utility-search {
			display: inline-block;
			margin: 0 0 0 0;
			border: none;

			vertical-align: middle;

			.js & {
				display: none;
				position: fixed;
				top: 0;
				left: 0;
				opacity: 0;
				z-index: $z-modal;
				margin-top: 44px;
				background-color: $color-gray-85;
				padding: $space-m;
				width: 100%;
				height: 100%;
				color: $color-white;

				@media screen and (min-width: $screen-sm-min) {
					display: inline-block;
					position: relative;
					opacity: 1;
					margin-top: 2px;
					margin-right: -1px;
					margin-bottom: 2px;
					background-color: $color-white;
					padding: 0;
					width: 200px;
					height: auto;
				}
			}

			&.is-animating {
				display: block;
				transition: opacity 0.25s ease-out;
			}

			&.is-visible {
				display: inline-block;
			}

			&.is-active {
				opacity: 1;
			}
		}

			.utility-search .input-group {
				@media screen and (max-width: $screen-s-max) {
					width: 100%;
				}
			}

			.utility-search input.form-control {
				border: none;
				background-color: $color-white;
				padding-left: 10px;
				height: 26px;
				font-size: 14px;
				color: $color-gray-85;
			    box-shadow: none;

				&::placeholder {
					font-size: 12px;
				}

				@media screen and (max-width: $screen-s-max) {
					height: 40px;
				}
			}

			.utility-search input.form-control::-webkit-input-placeholder {
				opacity: 1;
				color: $color-gray-40;
			}

			.utility-search input.form-control:-moz-placeholder { /* Firefox 18- */
				opacity: 1;
				color: $color-gray-40;
			}

			.utility-search input.form-control::-moz-placeholder {  /* Firefox 19+ */
				opacity: 1;
				color: $color-gray-40;
			}

			.utility-search input.form-control:-ms-input-placeholder {
				opacity: 1;
				color: $color-gray-40;
			}

			.utility-search .input-group-btn {
				@media screen and (max-width: $screen-s-max) {
					float: right;
					z-index: 100;
					margin-top: -40px;
					width: auto;

					.btn-s {
					    padding: 11px;
					}
				}
			}

			.utility-search .btn {
				margin-bottom: 0;
				background-color: $color-white;
				color: $color-gray-40;
			}

			.utility-search .btn-s {
				padding: 5px;
				font-size: 12px;
				line-height: normal;
			}

			.utility-search .help-block {
				display: inline-block;
				text-transform: uppercase;

				a[type=button],
				a[role=button] {
					-webkit-appearance: inherit;
					background-color: $color-primary-700;
					padding: 8px 16px;
					color: $color-white;
				}

				@media screen and (min-width: $screen-sm-min) {
					display: none;
				}
			}

			.utility-search .help-block .icon-container {
				vertical-align: baseline;
			}

				.utility-search .help-block .icon {
					width: 12px;
					height: 12px;
				}

		.utility-search .form-group {
			@media screen and (min-width: $screen-sm-min) {
				margin-bottom: 0;

				.form-control,
				.input-group-btn {
				    display: table-cell;
				}
			}
		}
	}


/*---------------------------------------------------------------------------*\
  #OBJECTS > #HEADER
\*---------------------------------------------------------------------------*/

#primary-nav {
	@include clearfix();

	padding-top: 44px;

	@media screen and (min-width: $screen-s-min) {
		padding: $space-xl $space-l $space-xl $space-m;
	}
}

	.header-brand .container {
		position: relative;
	}


/*---------------------------------------------------------------------------*\
  #OBJECTS > #HEADER > #LOGO
\*---------------------------------------------------------------------------*/

/**
 * Hide the logo by default on small viewports.
 */

.header-logo {
	display: none;

	@media screen and (min-width: $screen-s-min) {
		display: block;
	}
}

	a.header-name {
		display: block;
		float: left;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 43px;
		color: $color-black;
	}

	a.header-name-myapnic {
		display: block;
		float: left;
		text-decoration: none;
		font-size: 43px;
		color: $color-black;
	}

	.header-logo-image {
		display: block;
		float: left;
		max-width: 200px;
	}

	.header-logo-section {
		display: block;
		float: left;
		clear: both;
		margin-top: $space-xxs;
		margin-left: $space-s;
		font-size: $font-size-l;
		color: $color-gray-65;

		@media screen and (min-width: $screen-md-min) {
			clear: none;
			margin-top: 0;
			margin-left: $space-l;
			border-left: $border-width-m solid $color-gray-65;
			padding-left: $space-l;
			font-size: $font-size-xxl;
			line-height: 1.55;

		}
	}





/*---------------------------------------------------------------------------*\
  #OBJECTS > #HEADER > #GLYPH
\*---------------------------------------------------------------------------*/

#glyph.header-glyph {
	@media screen and (min-width: $screen-md-min) {
		display: block;
		position: absolute;
		top: -30px;
		right: 0;
		z-index: $z-below;
		background-image: url("images/apnic-icons.png");
		width: 150px;
		height: 120px;
	}
}

.header-glyph-blue-1 {
	background-position: 0 0;
}

.header-glyph-blue-2 {
	background-position: -150px 0;
}

.header-glyph-blue-3 {
	background-position: -300px 0;
}

.header-glyph-blue-4 {
	background-position: -450px 0;
}

.header-glyph-blue-5 {
	background-position: -600px 0;
}

.header-glyph-darkgreen-1 {
	background-position: 0 -150px;
}

.header-glyph-darkgreen-2 {
	background-position: -150px -150px;
}

.header-glyph-darkgreen-3 {
	background-position: -300px -150px;
}

.header-glyph-darkgreen-4 {
	background-position: -450px -150px;
}

.header-glyph-darkgreen-5 {
	background-position: -600px -150px;
}

.header-glyph-lightgreen-1 {
	background-position: 0 -300px;
}

.header-glyph-lightgreen-2 {
	background-position: -150px -300px;
}

.header-glyph-lightgreen-3 {
	background-position: -300px -300px;
}

.header-glyph-lightgreen-4 {
	background-position: -450px -300px;
}

.header-glyph-lightgreen-5 {
	background-position: -600px -300px;
}

.header-glyph-orange-1 {
	background-position: 0 -450px;
}

.header-glyph-orange-2 {
	background-position: -150px -450px;
}

.header-glyph-orange-3 {
	background-position: -300px -450px;
}

.header-glyph-orange-4 {
	background-position: -450px -450px;
}

.header-glyph-orange-5 {
	background-position: -600px -450px;
}

.header-glyph-purple-1 {
	background-position: 0 -600px;
}

.header-glyph-purple-2 {
	background-position: -150px -600px;
}

.header-glyph-purple-3 {
	background-position: -300px -600px;
}

.header-glyph-purple-4 {
	background-position: -450px -600px;
}

.header-glyph-purple-5 {
	background-position: -600px -600px;
}

.header-glyph-yellow-1 {
	background-position: 0 -750px;
}

.header-glyph-yellow-2 {
	background-position: -150px -750px;
}

.header-glyph-yellow-3 {
	background-position: -300px -750px;
}

.header-glyph-yellow-4 {
	background-position: -450px -750px;
}

.header-glyph-yellow-5 {
	background-position: -600px -750px;
}

/*---------------------------------------------------------------------------*\
  #OBJECTS > #HEADER > #SEARCH
\*---------------------------------------------------------------------------*/

.header-search {
	@media screen and (min-width: $screen-s-min) {
		float: right;
		margin-right: 0;
		background-color: $color-gray-05;
		width: 400px; /* Magic number, not good, need to optimise. */
		color: $color-black;
	}

	.js & {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: $z-modal;
		margin-top: 48px;
		background-color: $color-gray-85;
		width: 100%;
		height: 100%;
		color: $color-white;

		@media screen and (min-width: $screen-s-min) {
			display: block;
			position: relative;
			opacity: 1;
			margin-top: 0;
			background-color: $color-gray-05;
			width: 400px; /* Magic number, not good, need to optimise. */
			height: auto;
			color: $color-black;
		}
	}

	&.is-animating {
		display: block;
		transition: opacity 0.25s ease-out;
	}

	&.is-visible {
		display: block;
	}

	&.is-active {
		opacity: 1;
	}
}

	.header-search-links {
		display: none;

		@media screen and (min-width: $screen-s-min) {
			display: table;
			width: 100%;
		}
	}

		.header-search-link {
			display: table-cell;
			background-color: $color-white;
			font-size: $font-size-s;
			color: $color-gray-85;

			&:visited {
				color: $color-gray-85;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-gray-40;
			}
		}

		.header-search-link-default {
			background-color: $color-gray-10;

			&:hover,
			&:active,
			&:focus {
				background-color: $color-gray-05;
				text-decoration: none;
				color: $color-gray-85;
			}
		}

			.header-search-link .icon {
				margin-right: $space-xxs;
				width: $space-m;
				height: $space-m;
				vertical-align: initial;
			}

	.header-search-form {
		padding: $space-inset-s;
	}


	.header-search input {

		&::-webkit-input-placeholder {
			color: $color-gray-85;
		}

		&:-moz-placeholder { /* Firefox 18- */
			opacity: 1;
			color: $color-gray-85;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
			opacity: 1;
			color: $color-gray-85;
		}

		&:-ms-input-placeholder {
			color: $color-gray-85;
		}

		&::placeholder {
			opacity: 1;
			color: $color-gray-85;
		}
	}

	.header-search a {
		@media screen and (max-width: $screen-s-max) {
			color: $color-white;
		}

		&:hover,
		&:active,
		&:focus {
			@media screen and (max-width: $screen-s-max) {
				color: $color-white;
			}
		}
	}

	.header-search .help-block {
		@media screen and (min-width: $screen-s-min) {
			display: none;
		}
	}

/*---------------------------------------------------------------------------*\
  #OBJECTS > #HEADER > #MOBILE
\*---------------------------------------------------------------------------*/

.header-mobile {
	display: table;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-outer-space;
	background-color: $color-primary-400;
	width: 100%;

	@media screen and (min-width: $screen-s-min) {
		display: none;
	}
}

	.header-mobile-cell {
		display: table-cell;
		padding: $space-inset-s;
		text-align: center;
		color: $color-white;

		&:visited,
		&:hover,
		&:active,
		&:focus {
			color: $color-white;
		}
	}

	.header-mobile-navigation-toggle {
		border-right: $border-width-s solid $color-primary-200;
		width: 48px;
	}

	.header-mobile-logo {
		vertical-align: middle;
		text-align: center;
		font-size: $font-size-s;
		line-height: 1;
	}

		.header-mobile-logo img {
			max-width: 65px;
		}

	.header-mobile-search-toggle {
		border-left: $border-width-s solid $color-primary-200;
		width: 48px;
	}

/*---------------------------------------------------------------------------*\
#OBJECTS > #HEADER > #NAVIGATION
\*---------------------------------------------------------------------------*/

	.navigation {
		background-color: $color-white;

		@media screen and (min-width: $screen-sm-min) {
			@include clearfix();

			&::after {
				display: block;
				background-color: $color-primary-300;
				height: 3px;
			}
		}

		.js & {
			position: fixed;
			top: 0;
			left: 0;
			z-index: $z-modal;
			padding-top: 44px;
			width: 70%;
			height: 100%;
			overflow-y: scroll;
			transform: translate(-100%);

			@media screen and (min-width: $screen-sm-min) {
				position: relative;
				padding-top: 0;
				width: 100%;
				height: auto;
				overflow: visible;
				transform: none;
				transition: none;
			}

			&.is-animating {
				transition: transform 0.25s ease-in-out;
			}

			&.is-active {
				transform: translate(0);

				@media screen and (min-width: $screen-sm-min) {
					transform: none;
				}
			}
		}

		.navigation-menu {
			@include clearfix();

			margin: 0 1px;
			padding: 0;
			list-style-type: none;

			&.is-hidden {
				display: none;
			}

			@media screen and (min-width: $screen-sm-min) {
				float: right;
			}
		}

			.navigation-item {
				@include clearfix();

				display: block;
				position: relative;
				width: 100%;

				@media screen and (min-width: $screen-sm-min) {
					display: inline-block;
					width: auto;
					vertical-align: top;
				}

				&.has-dropdown::after {
					@media screen and (min-width: $screen-sm-min) {
						display: block;
						position: absolute;
						top: 50%;
						right: 14px;
						border-width: 5px 5px 0 5px;
						border-style: solid;
						border-color: $color-gray-20 transparent transparent transparent;
						width: 0;
						height: 0;
						content: "";
					}
				}

				&.is-active.has-dropdown::after,
				&.has-dropdown:hover::after {
					@media screen and (min-width: $screen-sm-min) {
						border-color: $color-white transparent transparent transparent;
					}
				}

				&.has-dropdown > a,
				&.has-dropdown > span {
					padding-right: $space-m + $space-m;
				}

				&:hover > a,
				&:hover > span {
					background-color: $color-gray-10;
					text-decoration: none;

					@media screen and (min-width: $screen-sm-min) {
						background-color: $color-primary-300;
						color: $color-white;
					}
				}

				&.is-active > a {
					background-color: $color-gray-10;

					@media screen and (min-width: $screen-sm-min) {
						background-color: $color-primary-300;
						color: $color-white;
					}
				}
			}

				.navigation-item > a,
				.navigation-item > span {
					display: block;
					background-color: $color-white;
					padding: $space-squish-l;
					color: $color-gray-85;

					@media screen and (min-width: $screen-sm-min) {
						background-color: $color-white;
						padding: $space-xs $space-m;
						color: #0081C6;
					}

					&:hover,
					&:active,
					&:focus {
						background-color: $color-gray-10;

						@media screen and (min-width: $screen-sm-min) {
							background-color: $color-primary-300;
							color: $color-white;
						}
					}
				}

				.navigation-item > span {
					cursor: default;
				}

				.navigation-item-more {
					display: none;

					&.is-active {
						display: inline-block;
					}
				}

				.navigation-more {
					@media screen and (max-width: $screen-sm-min) {
						display: none;
					}
				}

				.navigation-actions {
					@media screen and (min-width: $screen-sm-min) {
						display: none;
					}
				}

				.navigation-item-login-button {
					@media screen and (min-width: $screen-sm-min) {
						float: right;
					}

					&:hover > a
					&:hover > span {
						background-color: $color-primary-800;
					}
				}

				.navigation-item-login-button > a {
					background-color: $color-primary-700;
					color: $color-white;

					&:hover,
					&:active,
					&:focus {
						background-color: $color-primary-800;
					}
				}


				.navigation-actions {

					.navigation-item-button.panel {
						margin-bottom: 0;
						border-color: $color-white;

						.panel-heading {
							border: none;
							background-color: $color-primary-700;
							padding: $space-squish-l;
							color: $color-white;

							&:hover,
							&:active,
							&:focus {
								background-color: $color-primary-800;
							}
						}

						.list-group-item {
							border: none;
							background-color: $color-gray-05;
							padding: $space-squish-l;

							&:hover,
							&:active,
							&:focus {
								background-color: $color-gray-10;
							}
						}
					}
				}
			}

	/**
	 * Do not display sub menus on small viewports.
	 */

				.navigation-sub-menu {
					display: none;

					.navigation-item:hover & {
						@media screen and (min-width: $screen-sm-min) {
							display: block;
							position: absolute;
							margin: 0;
							background-color: rgba($color-primary-400, 0.95);
							padding: 0;
							width: 220px;
							list-style-type: none;
						}
					}

					.navigation-item.is-right:hover &,
					.navigation-more .navigation-item:hover & {
						@media screen and (min-width: $screen-sm-min) {
							right: 0;
						}
					}
				}

				.navigation-sub-menu .navigation-sub-menu {
					display: none;

					.navigation-item:hover & {
						@media screen and (min-width: $screen-sm-min) {
							display: none;
						}
					}
				}

				.navigation-sub-item {
					display: block;
				}

					.navigation-sub-item > a {
						display: block;
						padding: $space-squish-m;
						text-decoration: none;
						color: $color-white;

						&:hover,
						&:active,
						&:focus {
							background-color: $color-primary-500;
						}
					}

				.navigation-backdrop {
					position: fixed;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: $zindex-modal-background;
					background-color: $color-black;

					@media screen and (min-width: $screen-sm-min) {
						display: none;
					}

					&.fade.in {
						opacity: 0.5;
					}
				}
