/*---------------------------------------------------------------------------*\
  #SETTINGS > #TOKENS > #BORDER
\*---------------------------------------------------------------------------*/

$border-width-s: 1.374px;
$border-width-m: 3.356px;
$border-width-l: 6.554px;
$border-width-base: $border-width-m;

$border-radius-s: 0;
$border-radius-m:  0;
$border-radius-l: 0;
$border-radius-base: $border-radius-m;
