/*---------------------------------------------------------------------------*\
  #COMPONENTS > #LIST-GROUPS
\*---------------------------------------------------------------------------*/

@import "bootstrap/list-group";


.list-group {
	margin: $space-stack-base;
}

	.list-group-item {
		padding: $space-inset-s;
	}


/**
 * Increase the padding of list group items using a parent modifier class.
 */

.list-group-l .list-group-item {
	padding: $space-inset-base;
}
