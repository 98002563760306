/*---------------------------------------------------------------------------*\
  #COMPONENTS > #AVATARS
\*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*\
  #COMPONENTS > #AVATARS > #SIZING
\*---------------------------------------------------------------------------*/

.avatar-s {
	width: $space-s * 2.5;
	height: $space-s * 2.5;
}

.avatar-m {
	width: $space-m * 2.5;
	height: $space-m * 2.5;
}

.avatar-l {
	width: $space-l * 2.5;
	height: $space-l * 2.5;
}

.avatar-xl {
	width: $space-xl * 2.5;
	height: $space-xl * 2.5;
}





/*---------------------------------------------------------------------------*\
  #COMPONENTS > #AVATARS > #SHAPES
\*---------------------------------------------------------------------------*/

.avatar-circle {
	border-radius: 50%;
}
