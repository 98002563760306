/*---------------------------------------------------------------------------*\
  #TOOLS > #FUNCTIONS
\*---------------------------------------------------------------------------*/

/// Converts a px value to em.
/// @author APNIC
/// @param {Number} $value - The px value to convert.
/// @param {Number} $base [$font-size-base] - The base px value used when converting to em.
@function em($value, $base: $font-size-base) {
	@return ($value / $base) * 1em;
}

/// Converts a px value to rem.
/// @author APNIC
/// @param {Number} $value - The px value to convert.
/// @param {Number} $base [$font-size-base] - The base px value used when converting to rem.
@function rem($value, $base: $font-size-base) {
	@return ($value / $base) * 1rem;
}
